import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import closeButton from './../../assets/images/icon-plus.png';

export default function Scheduling() {

    const cpfInputRef = useRef();
    const [protocolResult, setProtocolResult] = useState(null);
    
    function sendQueryRecordsRequest() {

        let formData = new FormData();
        formData.append('record_protocol', cpfInputRef.current.value);

        const options = {
            method: 'POST',
            body: formData,
        };

        fetch('https://admin.rtdpjpatosdeminas.com.br/api/record-query', options)
            .then(response => {
                if (response.status === 429) {
                    alert('Por favor, aguarde 1 minuto para enviar uma nova requisição!');
                    return false;
                }
                return response.json()
            })
            .then(response => {
                if (response.message === 'wrong_action_method') {
                    alert('Contacte um administrador!');
                    return false;
                }

                if (response.message === 'not_found') {
                    alert('Protocolo não encontrado!');
                    return false;
                }

                setProtocolResult(response);
            });
    }
    

    function handleSubmit(event) {
        event.preventDefault();

        if (cpfInputRef.current.value.length !== 14) {
            alert('Por favor, preencha o protocolo corretamente!');
            return false;
        }

        sendQueryRecordsRequest();

    }

    function cpfMask(e) {
        e.preventDefault();
        let val = cpfInputRef.current.value;
        let keyCode = parseInt(e.key);

        if (isNaN(keyCode) || val.length >= 14) {
            return false;
        } else {
 
            if (val.length === 3) {
                cpfInputRef.current.value += '.'
            }

            if (val.length === 7) {
                cpfInputRef.current.value += '.'
            }

            if (val.length === 11) {
                cpfInputRef.current.value += '-'
            }

            cpfInputRef.current.value += keyCode;

        }
    }

    return (
        <section className="scheduling">
            <div className="container">
                <div className="row p-5">
                    <div className="col-12">
                        <Link to={'/'} className="d-flex justify-content-end">
                            <button className="close-button">
                                <img src={closeButton} alt="" />
                            </button>
                        </Link>
                    </div>
                    <div className="col-12">
                        <h5 className="section-introduction">Atendimento Online</h5>
                        <h2 className="section-title">Consulta de Registro</h2>
                        <p className="section-description">Digite o número do Protocolo para consultar seus registros.</p>
                    </div>
                    <div className="col-12 col-sm-10 offset-sm-1 mt-5">
                        {
                            !protocolResult && 
                            <>
                                <form className="scheduling-form" onSubmit={ (e) => handleSubmit(e) }>
                                    <div className="form-group">
                                        <label>CPF do Apresentante</label>
                                        <input 
                                            type="tel" 
                                            className="form-control"
                                            ref={ cpfInputRef } 
                                            minLength="4"
                                            maxLength="14"
                                            required
                                            onKeyPress={ (event) => cpfMask(event) }
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center mt-5'>
                                        <button type="submit">Procurar Registro</button>
                                    </div>
                                </form>
                            </>
                        }

                        {
                            protocolResult && (
                                <div className='text-center'>
                                    <h2 className='fw-bolder mb-4'>Protocolos encontrados!</h2>
                                    <div className="row">
                                        {
                                            protocolResult.map((item, index) => {
                                                return (
                                                    <div className="col-12 col-md-6 border border-3 py-5" key={ index }>
                                                        <h4 className='fs-4'>Número do Protocolo</h4>
                                                        <h2 className="fw-bolder fs-2 mt-3">{ item.protocol }</h2>
                                                        <h4 className='fs-4 mt-3'>Nome do Responsável</h4>
                                                        <h2 className="fw-bolder fs-2 mt-3">{ item.person_name }</h2>
                                                        <h4 className='mt-4 fs-3 fw-bolder'>
                                                            Status do Pedido
                                                            {
                                                                item.status === 1 ? 
                                                                    <div>
                                                                        <div className='text-primary fw-bolder record-status'>Em Andamento</div>
                                                                    </div> :
                                                                    item.status === 2 ? 
                                                                    <div>
                                                                        <div className='text-success fw-bolder record-status'>Concluído</div>
                                                                    </div>
                                                                    : 
                                                                    <div>
                                                                        <div className='text-danger fw-bolder record-status'>Cancelado</div>
                                                                    </div>
                                                            }
                                                        </h4>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        

                    </div>
                </div>
            </div>
        </section>
    );
}