import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Fixed from './Components/Fixed.js';

import LgpdAgreement from './Components/LgpdAgreement.js';

import MainPage from './Components/MainPage.js';
import Scheduling from './Components/MainPage/Scheduling.js';
import QueryRecords from './Components/MainPage/QueryRecords.js';
import Table from './Components/MainPage/Table.js';

import AboutUs from './Components/AboutUs.js';
import Services from './Components/Services.js';
import Faq from './Components/Faq.js'
import Gallery from './Components/Gallery.js'
import UsefulLinks from './Components/UsefulLinks.js';
import Contact from './Components/Contact.js';
import Lgpd from './Components/Lgpd.js';

import Footer from './Components/Footer.js';


export default function App() {

    const [content, setContent] = useState();
    const [lgpdAgreement, setLgpdAgreement] = useState(false);

    useEffect(() => {
        fetch("https://cdn.rtdpjpatosdeminas.com.br/json/UniqueContent.json")
            .then(data => data.json())
            .then(data => {
                setContent(data);
            });
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('rtdpj-lgpd-agreement')) {
            setLgpdAgreement(true);
        }
    }, []);



    return (
        <BrowserRouter>
            
            { 
                content &&
                <>
                    {
                        lgpdAgreement && <LgpdAgreement setLgpdAgreement={ setLgpdAgreement }/>
                    }
                    <Fixed content={content.main_page_content.info_content}></Fixed>
                    <Routes>
                        <Route path="/" element={<MainPage content={content.main_page_content} services_content={content.services_content} />}>
                            <Route path="agendamento" element={<Scheduling content={content.main_page_content.online_services_content} />}/>
                            <Route path="consultar-registro" element={<QueryRecords content={content.main_page_content.online_services_content} />}/>
                            <Route path="tabela-emolumentos" element={<Table />}/>
                        </Route>
                        <Route path="/sobre-nos" element={<AboutUs content={content.about_us_content} />} />
                        <Route path="/servicos" element={<Services content={content.services_content} />} />
                        <Route path="/perguntas-frequentes" element={<Faq content={content.faq_content} />} />
                        <Route path="/galeria" element={<Gallery content={content.gallery_content} />} />
                        <Route path="/transparencia" element={<UsefulLinks content={content.usefuls_links_content} />} />
                        <Route path="/contato" element={<Contact content={content.contact_content} />} />
                        <Route path="/lgpd" element={<Lgpd content={content.lgpd_content} />} />

                    </Routes>  
                    <Footer 
                        usefulLinks={ content.usefuls_links_content } 
                        contactOptions={ content.contact_content }
                    />
                </>
            }
            
        </BrowserRouter>
    );
}