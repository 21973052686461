import DOMPurify from 'dompurify';

export default function Main({ content }) {

    const sanitizeContent = DOMPurify.sanitize(content.text, { USE_PROFILES: { html: true } } );
    const sanitizeAlert = DOMPurify.sanitize(content.alert_message, { USE_PROFILES: { html: true } } );

    return (
        <>
            <main>
                <div className="container-fluid container-lg">
                    <div className="row position-relative">
                        <div className="col-12 col-md-5 col-lg-5 header-img d-flex justify-content-center align-items-center">
                            <img src={ content.image } alt="" />
                        </div>
                        <div className="header-text position-absolute d-flex flex-column col-12 h-100 justify-content-center 
                        align-items-center px-5 text-sm-center d-md-none">
                            <div className="container">
                                <h1>{ content.title }</h1>
                                <p 
                                    dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                                    className="mt-4"
                                ></p>
                            </div>
                        </div>
                        <div className="header-text d-none d-md-flex col-md-7 col-lg-7 align-items-center">
                            <div className="container px-4 px-lg-5">
                                <h1>{ content.title }</h1>
                                <p 
                                    className="mt-4"
                                    dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                                ></p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <section className="alert container-lg py-4 py-lg-4">
                <div className="container text-center">
                    <h4 
                        className="fw-bold mb-0"
                        dangerouslySetInnerHTML={{ __html: sanitizeAlert }}
                    ></h4>
                </div>
            </section>
        </>
    );
}