import ServiceItem from "./Services/ServiceItem.js";

export default function Services({ content }) {

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.content }</p>
                    </div>
                    <div className="col-12 mt-4 mt-md-5">
                        <div className="row px-5 px-lg-0">
                            {
                                content.services.map((item, index) => {
                                    return <ServiceItem content={ item } key={ index } />;
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}