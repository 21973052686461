import DOMPurify from 'dompurify';

export default function Services({ content }) {

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.content }</p>
                    </div>
                    <div className="col-12 mt-4 mt-md-5">
                        <div className="row px-5 px-lg-0">
                            {
                                content.services &&
                                content.services.slice(0, 4).map((item, key) => {
                                    const sanitizeContent = DOMPurify.sanitize(item.service_description, { USE_PROFILES: { html: true } } );
                                    return (
                                        <div className="col-12 col-sm-6 col-lg-3 col-xl-3 mb-5 service" key={ key }>
                                            <div className="hold-image d-flex justify-content-center align-items-center">
                                                <img src={ item.service_image } alt="" />
                                            </div>
                                            <h5 className="service-category mt-4">{ item.service_category }</h5>
                                            <h4 className="service-title">{ item.service_title }</h4>
                                            <div 
                                                className="service-description"
                                                dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                                            ></div>
                                            <div className="d-flex justify-content-center">
                                                <a 
                                                    href={ item.service_link } 
                                                    className="service-button"
                                                    target="__blank"
                                                    rel="noreferrer"
                                                >Acessar Serviço</a>
                                            </div>
                                            
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}