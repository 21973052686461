import React, {useState} from 'react';
import DOMPurify from 'dompurify';
import plusIcon from './../../assets/images/icon-plus.png';
import minusIcon from './../../assets/images/icon-minus.png';


export default function FaqItem({ content }) {
    const [showDescription, setShowDescription] = useState(false);
    const sanitizeContent = DOMPurify.sanitize(content.faq_content, { USE_PROFILES: { html: true } } );

    return (
        <div 
            className="col-12 col-md-8 offset-md-2 faq-item d-flex flex-column p-3 mb-4" 
            onClick={ () => setShowDescription(!showDescription) }
        >
            <div className='d-flex align-items-center justify-content-between gap-3 px-3'>
                <h3>{ content.faq_title }</h3>
                <img src={ showDescription ?  minusIcon : plusIcon } alt="" className='faq-icon'/>
            </div>
            {
                showDescription && 
                <div className="faq-info">
                    <p dangerouslySetInnerHTML={{ __html: sanitizeContent }}></p>
                </div>
            }
            
        </div>
    );
}