import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './assets/css/custom.css';

import React from 'react';
import {render} from 'react-dom';
import App from './App.js';

const rootElement = document.getElementById('root');

render (
  <>
    <App></App>
  </>
, rootElement);

