import { useState } from 'react';
import FaqItem from './Faq/FaqItem.js';

export default function Faq({ content }) {

    const [faqCategory, setFaqCategory] = useState('rtd');

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">

                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.description }</p>
                    </div>

                    <div className="col-12 col-md-8 offset-md-2 my-4">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <button 
                                    className={ `faq-button ${ faqCategory === 'rtd' ? 'active' : '' }` }
                                    onClick={ () => setFaqCategory('rtd') }
                                >
                                    Registro de Títulos e Documentos
                                </button>
                            </div>
                            <div className="col-12 col-md-6">
                                <button 
                                    className={ `faq-button ${ faqCategory === 'rcpj' ? 'active' : '' }` }
                                    onClick={ () => setFaqCategory('rcpj') }
                                >
                                    Registro de Pessoas Jurídicas
                                </button>
                            </div>
                        </div>
                    </div>

                    {
                        faqCategory === 'rtd' && (
                            <div className="col-12 mt-5">
                                <h3 className="section-title">Perguntas Frequentes Sobre RTD</h3>
                                <div className="row px-5 px-lg-0 mt-5">
                                    {
                                        content.faqs.map((item, key) => {
                                            if (item.faq_category !== 'rtd') return false;
                                            return <FaqItem content={ item } key={ key } />    
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                    
                    {
                        faqCategory === 'rcpj' && (
                            <div className="col-12 mt-5">
                                <h3 className="section-title">Perguntas Frequentes Sobre RCPJ</h3>
                                <div className="row px-5 px-lg-0 mt-5">
                                    {
                                        content.faqs.map((item, key) => {
                                            if (item.faq_category !== 'rcpj') return false;
                                            return <FaqItem content={ item } key={ key } />    
                                        })
                                    }
                                </div>
                            </div>

                        )
                    }

                </div>
            </div>
        </section>
    );
}