import React, {useEffect, useState} from 'react';
import GalleryItem from "./Gallery/GalleryItem.js";
import GalleryLightbox from "./Gallery/GalleryLightbox.js";

export default function Gallery({ content }) {

    const [lightbox, setLightbox] = useState(false);
    const [choosedImage, setChoosedImage] = useState(null);


    return (
        <>
            <section className="py-5 services">
                <div className="container-fluid container-lg">
                    <div className="row my-xl-5">
                        <div className="col-12">
                            <h5 className="section-introduction">{ content.introduction }</h5>
                            <h2 className="section-title">{ content.title }</h2>
                            <p className="section-description">{ content.description }</p>
                        </div>
                        <div className="col-12 mt-4 mt-md-5">
                            <div className="row px-5 px-lg-0">
                                {
                                    content.images.map((item, index) => {
                                        return (
                                            <GalleryItem 
                                                    setLightbox={() => setLightbox(true)} 
                                                    setChoosedImage={() => setChoosedImage(item.image)}
                                                    image={ item.image }
                                                    key={ index }
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            { lightbox && <GalleryLightbox setLightbox={() => setLightbox(false)} choosedImage={ choosedImage } /> }
        </>
    );
}