import zoomIcon from "./../../assets/images/icon-zoom.png";

export default function GalleryItem({ setLightbox, image, setChoosedImage }) {
    return (
        <div className="col-12 col-sm-6 col-lg-4 mb-5 gallery-item">
            <div 
                className="hold-image d-flex justify-content-center align-items-center position-relative"
                onClick={ () => { setLightbox(true); setChoosedImage(image) } }
            >
                <img src={ image } alt="" />
                <div className="gallery-zoom justify-content-center align-items-center">
                    <img src={zoomIcon} alt=""/>
                </div>
            </div>
            
        </div>
    );
}