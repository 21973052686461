import { useRef } from 'react';

export default function Form() {

    const inputName = useRef(null);
    const inputMail = useRef(null);
    const inputSubject = useRef(null);
    const inputMessage = useRef(null);

    async function sendRequest(data) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('mail', data.mail);
        formData.append('subject', data.subject);
        formData.append('message', data.message);

        const request = await fetch('https://admin.rtdpjpatosdeminas.com.br/api/contato', {
            method: 'POST',
            body: formData,
        });

        if (request.status === 200) {
            const data = await request.json();

            if (data.message === 'successful') {
                alert('Contato enviado com sucesso! Responderemos você em breve!');
                return true;
            }

            alert('Houve um erro de comunicação no envio do e-mail! Contate-nos nos utilizando nossos números de telefone!');
            return false;
        }
    }

    function handleSubmit(event) {

        event.preventDefault();

        const inputGroup = [inputName, inputMail, inputSubject, inputMessage];

        const formValidation = inputGroup.every((item) => {
            if (item.current.value === '') {
                return false;
            }

            if (item.current.value.length < 4) {
                return false;
            }

            return true;
        });

        if (formValidation) {
            const data = {
                'name': inputName.current.value,
                'mail': inputMail.current.value,
                'subject': inputSubject.current.value,
                'message': inputMessage.current.value,
            };

            return sendRequest(data);
        }


    }

    return (
        <form className="scheduling-form" onSubmit={ (e) => handleSubmit(e) }>
            <div className="form-group">
                <label>Nome Completo</label>
                <input 
                    type="text" 
                    className="form-control" 
                    required
                    name="nome"
                    ref={ inputName }
                />
            </div>
            <div className="form-group">
                <label>E-mail</label>
                <input 
                    type="tel" 
                    className="form-control" 
                    required
                    name="email"
                    ref={ inputMail }
                />
            </div>
            <div className="form-group">
                <label>Assunto</label>
                <input 
                    type="tel" 
                    className="form-control" 
                    required
                    name="assunto"
                    ref={ inputSubject }
                />
            </div>
            <div className="form-group">
                <label>Mensagem</label>
                <textarea
                    type="tel" 
                    className="form-control"
                    rows="8"
                    name="mensagem"
                    ref={ inputMessage }
                    required
                ></textarea>
            </div>
            <div className='d-flex justify-content-center mt-5'>
                <button type="submit">Enviar Mensagem</button>
            </div>
        </form>
    );
}