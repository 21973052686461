import React, { useRef } from 'react';

export default function ScheduleForm({ choosedDay, choosedHour, formatedDay, setScheduleStatus}) {

    const nameInputRef = useRef();
    const phoneInputRef = useRef();
    

    function sendScheduleRequest(userData) {
        const formatedData = `${ formatedDay } ${choosedHour}:00`;
        
        let formData = new FormData();
        formData.append('schedule_person_name', nameInputRef.current.value);
        formData.append('schedule_phone', phoneInputRef.current.value);
        formData.append('schedule_date', formatedData);

        const options = {
            method: 'POST',
            body: formData,
        };

        fetch('https://admin.rtdpjpatosdeminas.com.br/api/scheduling', options)
            .then(response => {
                return response.json();
            })
            .then(response => {
                if (response.message === 'wrong_action_method') {
                    alert('Contacte um administrador!');
                }

                if (response.message === 'already_exists') {
                    alert('Esse horário já está reservado!');
                }

                if (
                    response.message === 'not_allowed_period' ||
                    response.message === 'not_allowed_day' ||
                    response.message === 'not_alowed_hour' ||
                    response.message === 'not_allowed_minutes' 
                ) {
                    alert('A data escolhida não é permitida!');
                }

                if (response.message === 'unauthorized') {
                    alert('Atenção, contacte um administrador!');
                    return false;
                }


                if (response.message === 'success') {
                    setScheduleStatus(true);
                }

            });
    }


    function handleSubmit() {

        let data = {
            'name': nameInputRef.current.value,
            'phone': phoneInputRef.current.value,
        };

        if (!data.name || !data.phone) {
            alert ('Atenção, preencha todos os dados!');
            return false;
        }

        if (!choosedDay || !choosedHour) {
            alert ('Atenção, selecione a data e a hora desejada!');
            return false;
        }

        if (data.name.length < 5) {
            alert ('Preencha o nome corretamente!');
            return false;
        }
        if (data.phone.length < 14) {
            alert ('Preencha o telefone corretamente');
            return false;
        }

        sendScheduleRequest(data);
    }

    function phoneMask(e) {
        e.preventDefault();
        let val = phoneInputRef.current.value;
        let keyCode = parseInt(e.key);

        if (isNaN(keyCode) || val.length >= 14) {
            return false;
        } else {
 
            if (val.length === 0) {
                phoneInputRef.current.value += '('
            }

            if (val.length === 3) {
                phoneInputRef.current.value += ') '
            }

            phoneInputRef.current.value += keyCode;

        }
    }

    function phoneFix(e) {
        let val = phoneInputRef.current.value;

        if (val.length === 10) {
            phoneInputRef.current.value = `(${ val.substr(0, 2) }) ${ val.substr(2, 4) }-${ val.substr(6, 4) }`;
        }

        if (val.length === 11) {
            phoneInputRef.current.value = `(${ val.substr(0, 2) }) ${ val.substr(2, 5) }-${ val.substr(7, 4) }`;
        }

        if (val.length === 13) {
            let actualValue = phoneInputRef.current.value;
            phoneInputRef.current.value = actualValue.substr(0, 9);
            phoneInputRef.current.value += `-${ actualValue.substr(9, 4) }`;
        }

        if (val.length === 14) {
            let actualValue = phoneInputRef.current.value;
            phoneInputRef.current.value = actualValue.substr(0, 10);
            phoneInputRef.current.value += `-${ actualValue.substr(10, 5) }`;
        }
    }


    return (
        <>
            <div className="col-12 mt-5 text-center">
                <h2 className='fw-bolder'>Preencha seus dados</h2>
            </div>
            <div className="col-12 offset-md-2 col-md-8 mt-4">
                <form className="scheduling-form" autoComplete="false">
                    <div className="form-group">
                        <label>Nome Completo</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            ref={ nameInputRef }
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Telefone</label>
                        <input 
                            type="tel" 
                            className="form-control" 
                            autoComplete="false"
                            onKeyPress={(e) => phoneMask(e)}
                            onBlur={(e) => phoneFix(e)}
                            ref={ phoneInputRef }
                            required
                        />
                    </div>
                    <div className='d-flex justify-content-center'>
                        <button type="button" onClick={() => handleSubmit()}>
                            Marcar Horário
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}