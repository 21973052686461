import { Link } from 'react-router-dom';
import calendarIcon from './../../assets/images/icon-calendar.png';
import folderIcon from './../../assets/images/icon-folder.png';
import tableIcon from './../../assets/images/icon-table.png';
import centralIcon from './../../assets/images/icon-central.png';

export default function onlineServices({ content }) {


    return (
        <article className="py-5">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.description }</p>
                    </div>
                    <div className="col-12 col-lg-10 offset-lg-1 mt-4 my-md-5">
                        <div className="container">
                            <div className="row gap-4 gap-lg-0">
                                <div className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6">
                                    <Link to={"/agendamento"} className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex align-items-center text-start text-sm-start justify-content-sm-center">
                                            <img src={ calendarIcon } alt=""/>
                                            { content.scheduling_button }
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6">
                                    <Link to={"/consultar-registro"} className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex align-items-center text-start text-sm-start justify-content-sm-center">
                                            <img src={ folderIcon } alt=""/>
                                            { content.query_records_button }
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6 d-flex justify-content-center">
                                    <Link to={"/tabela-emolumentos"} className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex align-items-center text-start text-sm-start justify-content-sm-center">
                                            <img src={ tableIcon } alt=""/>
                                            { content.price_table_button }
                                        </button>
                                    </Link>
                                </div>
                                <div className="col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6">
                                    <a href="https://www.rtdbrasil.org.br/" target="_blank" rel="noreferrer" className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex align-items-center text-start text-sm-start justify-content-sm-center">
                                            <img src={ centralIcon } alt=""/>
                                            Central de Serviços Eletrônicos
                                        </button>
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}