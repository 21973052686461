import mailIcon from './../../assets/images/icon-mail-blue.png';
import mapIcon from './../../assets/images/icon-map-blue.png';
import phoneIcon from './../../assets/images/icon-phone-blue.png';
import whatsappIcon from './../../assets/images/icon-whatsapp-blue.png';
import watchIcon from './../../assets/images/icon-watch-blue.png';

export default function ContactRow({ content }) {
    return (
        <div className="col-12 d-flex align-items-center contact-row">
            <img 
                src={ 
                    content.icon === 'mail' ? mailIcon :
                    content.icon === 'phone' ? phoneIcon :
                    content.icon === 'address' ? mapIcon :
                    content.icon === 'whatsapp' ? whatsappIcon :
                    watchIcon
                } alt="" 
            />
            <h1>{ content.content }</h1>
        </div>
    );
}