import closeButton from './../../assets/images/icon-plus.png'

export default function Advices({ content, showAdvice })
{

    function saveLocalstorageReadConfirm() {
        localStorage.setItem('rtd-advice-last-read', Date.now());
    }

    return (
        <div className="advices">
            <div className="container">
                <div className="w-100 d-flex justify-content-end">
                    <button 
                        className="close-button" 
                        onClick={ () => { showAdvice(false); saveLocalstorageReadConfirm(); } }>
                        <img src={ closeButton } alt="" />
                    </button>
                </div>
                <div className="hold-advices">
                    <h1 className="text-center fw-bolder">Avisos Importantes</h1>
                    <div className="row mt-3">
                        {
                            content.map((item, index) => {
                                return (
                                    <div className="col-12 border-bottom border-2 my-4" key={ index }>
                                        <h2 className="fw-bolder">{ item.advice_title }</h2>
                                        <p>{ item.advice_content }</p>
                                    </div>
                                );
                            })
                        }
                        
                        
                    </div>
                </div>
            </div>
        </div>
    );
}