import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom';
import closeButton from './../../assets/images/icon-plus.png';

export default function Table() {

    return (
        <section className="scheduling">
            <div className="container">
                <div className="row p-5">
                    <div className="col-12">
                        <Link to={'/'} className="d-flex justify-content-end">
                            <button className="close-button">
                                <img src={closeButton} alt="" />
                            </button>
                        </Link>
                    </div>
                    <div className="col-12">
                        <h5 className="section-introduction">Tabela de Custas</h5>
                        <h2 className="section-title">Tabela de Emolumentos</h2>
                        <p className="section-description text-center">Valores dos serviços prestados pelo cartório</p>
                    </div>
                    
                    <div className="col-12 col-lg-10 offset-lg-1 mt-4 my-md-5">
                        <div className="container">
                            <div className="row gap-4 gap-lg-0">
                                <div className="col-12">
                                    <a href="https://www.serjus.com.br/arqdoc/2022/Tabela%205.pdf" target="_blank" rel="noreferrer" className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex justify-content-center align-items-center fs-3">
                                            Atos do Oficial de Registro de Títulos e Documentos
                                        </button>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a href="https://www.serjus.com.br/arqdoc/2022/Tabela%206.pdf" target="_blank" rel="noreferrer" className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex justify-content-center align-items-center fs-3">
                                            Atos do Oficial de Registro Civil das Pessoas Jurídicas
                                        </button>
                                    </a>
                                </div>
                                <div className="col-12">
                                    <a href="https://www.serjus.com.br/arqdoc/2022/Tabela%208.pdf" target="_blank" rel="noreferrer" className="d-flex justify-content-center">
                                        <button className="request-button col-12 col-sm-10 col-lg-12 d-flex justify-content-center align-items-center fs-3">
                                            Atos comuns a Registradores e Notários
                                        </button>
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}