import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Advices from './../Components/MainPage/Advices';
import Main from './../Components/MainPage/Main';
import OnlineServices from './../Components/MainPage/OnlineServices';
import Services from './../Components/MainPage/Services';
import Location from './../Components/MainPage/Location';


export default function MainPage({ content, services_content }) {
    const [adviceResponse, setAdviceResponse] = useState(null);
    const [adviceContent, setAdviceContent] = useState(null);
    const [showAdvice, setShowAdvice] = useState(false);

    function getAdvices() {
        fetch('https://admin.rtdpjpatosdeminas.com.br/api/advices')
            .then(response => {
                return response.json()
            })
            .then(response => {
                setAdviceResponse(response);
            });
    }


    useEffect(() => {
        getAdvices();
    }, []);

    useEffect(() => {
        if (adviceResponse) {
            const savedData = localStorage.getItem('rtd-advice-last-read').substring(0, 10) || null;
            const lastUploadedAdvice = adviceResponse.last_upload;

            if (savedData < lastUploadedAdvice ) {
                setAdviceContent(adviceResponse.all_advices);
                setShowAdvice(true);
            } else {
                setShowAdvice(false);
            } 
        } else {
            return false;
        }
        
    }, [adviceResponse]);



    return (
        <>
            {
                (adviceContent && showAdvice) && 
                    <Advices 
                        content={ adviceContent } 
                        showAdvice={ () => setShowAdvice(false) }
                    />
            }
            <Main content={ content.main_content } />
            <OnlineServices content={ content.online_services_content }/>
            <Services content={ services_content }/>
            <Location content={ content.location_content }/>
            <Outlet />
        </>
    );
}