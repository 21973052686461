import React from 'react';
import mapIcon from './../../assets/images/icon-map.png';
import mailIcon from './../../assets/images/icon-mail.png';
import phoneIcon from './../../assets/images/icon-phone.png';
import whatsappIcon from './../../assets/images/icon-whatsapp.png';
import watchIcon from './../../assets/images/icon-watch.png';

export default function Topbar({ content }) {
    return (
        <nav className="d-none d-lg-block">
            <div className="top-bar">
                <div className="container">
                    <div className="row d-none d-lg-flex align-items-center justify-content-between ">
                        {
                            content.map((item, index) => {
                                return (
                                    <div className="col d-flex align-items-center justify-content-center" key={ index }>
                                        <img 
                                            src={ 
                                                item.icon === 'mail' ? mailIcon :
                                                item.icon === 'phone' ? phoneIcon :
                                                item.icon === 'address' ? mapIcon :
                                                item.icon === 'whatsapp' ? whatsappIcon :
                                                watchIcon
                                             } 
                                            className="icon-topbar" 
                                            alt=""
                                            
                                        />
                                        
                                        { item.content }
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </nav>
    );
}