import { Link } from 'react-router-dom';
import mapIcon from './../assets/images/icon-map.png';
import mailIcon from './../assets/images/icon-mail.png';
import phoneIcon from './../assets/images/icon-phone.png';
import whatsappIcon from './../assets/images/icon-whatsapp.png';
import watchIcon from './../assets/images/icon-watch.png';
import logoFooter from './../assets/images/logo-footer.png';

export default function Footer({ usefulLinks, contactOptions }) {


    function scrollTop() { window.scrollTo(0, 0); }

    return (
        <footer className="py-4 py-lg-5">
            <div className="container container-lg">
                <div className="row px-5 px-md-2 my-lg-2">
                    <div className="col-12 col-md-4 d-flex justify-content-center align-items-start mb-md-4">
                        <img src={ logoFooter } alt="" className="w-100 footer-logo pt-md-2" />
                    </div>
                    <div className="col-12 col-md-4 mt-4 text-center text-md-start">
                        <h5>Mapa do Site</h5>
                        <ul className="ps-0">
                            <li>
                                <Link to="/" onClick={() => scrollTop() }>Início</Link>
                            </li>
                            <li>
                                <Link to="/sobre-nos" onClick={() => scrollTop() }>Sobre Nós</Link>
                            </li>
                            <li>
                                <Link to="/servicos" onClick={() => scrollTop() }>Serviços</Link>
                            </li>
                            <li>
                                <Link to="/perguntas-frequentes" onClick={() => scrollTop() }>Perguntas Frequentes</Link>
                            </li>
                            <li>
                                <Link to="/galeria" onClick={() => scrollTop() }>Galeria</Link>
                            </li>
                            <li>
                                <Link to="/links-uteis" onClick={() => scrollTop() }>Links Úteis</Link>
                            </li>
                            <li>
                                <Link to="/contato" onClick={() => scrollTop() }>Contato</Link>
                            </li>
                            <li>
                                <Link to="/lgpd" onClick={() => scrollTop() }>LGPD</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-4 mt-4 text-center text-lg-start">
                        <h5>Contato e Localização</h5>
                        <ul className="ps-0 d-flex flex-wrap justify-content-center gap-1">
                            {
                                contactOptions.contact_items.map((item, index) => {
                                    if (item.icon === 'address') return false;
                                    return (
                                        <li className="col-12 d-flex justify-content-center justify-content-md-start align-items-center gap-1" key={ index }>
                                            <img 
                                                src={ 
                                                    item.icon === 'mail' ? mailIcon :
                                                    item.icon === 'phone' ? phoneIcon :
                                                    item.icon === 'whatsapp' ? whatsappIcon :
                                                    watchIcon
                                                } className="footer-icon" alt="" 
                                            />
                                            { item.content }
                                        </li>
                                    );
                                })
                            }
                            <li className="col-12 gap-1">
                                <img src={ mapIcon } className="footer-icon" alt="" />
                                <span className="fw-bold">R. José de Santana, 345, sala 203, 2º andar </span> - Patos de Minas - MG, 38700-052
                            </li>
                        </ul>
                    </div>
                    
                    
                </div>
                <div className="row">
                    <div className="col-10 offset-1 px-5 py-3 py-md-4 mt-md-2 mt-lg-5 text-center footer-stroke">
                        <p>Cartório de Registro de Títulos e Documentos e Civil das Pessoas Jurídicas</p>
                        <p className="copyright">
                            &copy; Copyright { (new Date()).getFullYear() } - Todos os Direitos Reservados
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}