import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import ScheduleForm from './Scheduling/ScheduleForm';
import closeButton from './../../assets/images/icon-plus.png';

export default function Scheduling() {

    const [choosedDay, setChoosedDay] = useState(null);
    const [choosedHour, setChoosedHour] = useState(null);

    const [formatedDay, setFormatedDay] = useState(null);
    const [scheduleStatus, setScheduleStatus] = useState(null);


    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function(days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);

        return date;
    }

    let date = new Date();

    const dates = [];

    for (let i = 0; dates.length < 22; i++) {

        let newDate = date.addDays(i);

        if (newDate.getDay() !== 6 &&  newDate.getDay() !== 0) {
            //DateInfo, reduced variable name to a easier compose
            let d = {
                dayOfWeek: newDate.getDay(),
                year: newDate.getFullYear(),
                month: newDate.getMonth() < 9 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1,
                day: newDate.getDate() < 9 ? `0${newDate.getDate()}` : newDate.getDate(),
                hours: newDate.getHours() < 9 ? `0${newDate.getHours()}` : newDate.getHours(),
                minutes: newDate.getMinutes() < 9 ? `0${newDate.getMinutes()}` : newDate.getMinutes(),
                seconds: '00',
            };

            let dayOfWeek;

            switch (d.dayOfWeek) {
                case 1:
                    dayOfWeek = 'Segunda-Feira';
                    break;
                case 2:
                    dayOfWeek = 'Terça-Feira';
                    break;
                case 3:
                    dayOfWeek = 'Quarta-Feira';
                    break;
                case 4:
                    dayOfWeek = 'Quinta-Feira';
                    break;
                case 5:
                    dayOfWeek = 'Sexta-Feira';
                    break;
                default:
                        break;
            }

            let composedDate = `${d.year}-${d.month}-${d.day}`;
            let visualDate = `${d.day}/${d.month}/${d.year}`;

            dates.push({
                composedDate: composedDate,
                visualDate: visualDate,
                dayOfWeek: dayOfWeek,
            });
        }
        
    }

    const hours = [];
    const initialHour = 9;

    for (let i = 0; i < 8; i++) {
        let hour = initialHour + i;

        if (hour < 10) {
            hour = `0${hour}`;
        }

        let zeroMin = `${hour}:00`;
        let twentyMin = `${hour}:20`;
        let fortyMin = `${hour}:40`;

        if (hour !== 12){
            hours.push(zeroMin, twentyMin, fortyMin);
        }
    }


    return (
        <section className="scheduling">
            <div className="container">
                <div className="row px-5 py-4">
                    <div className="col-12">
                        <Link to={'/'} className="d-flex justify-content-end">
                            <button className="close-button">
                                <img src={closeButton} alt="" />
                            </button>
                        </Link>
                    </div>
                    <div className="col-12">
                        <h5 className="section-introduction">Atendimento Online</h5>
                        <h2 className="section-title">Agendamento de Atendimento Presencial</h2>
                        <p className="section-description">Selecione um dia, e em seguida, o horário desejado.</p>
                    </div>
                    <div className="col-12 my-3 text-center d-flex justify-content-around">
                        <h1 className='fw-bolder'>
                            { choosedDay && `Data: ${choosedDay}` }
                        </h1>
                        <h1 className='fw-bolder'>
                            { choosedHour && `Horário: ${choosedHour}h` }
                        </h1>
                    </div>
                    {
                        (!choosedDay && !scheduleStatus )&& 
                        <div className="col-12 d-flex flex-wrap gap-2 mt-4">
                            {
                                dates &&
                                dates.map((item, index) => {
                                    return (
                                        <button 
                                            className="schedule-day" 
                                            onClick={ () => { setChoosedDay(item.visualDate); setFormatedDay(item.composedDate) } }
                                            data-composed-date={ item.composedDate }
                                            key={ index }
                                        >
                                            <p>{ item.dayOfWeek }</p>{ item.visualDate }
                                        </button>
                                    );
                                })
                            }
                        </div>
                    }
                    {
                        (choosedDay && !choosedHour && !scheduleStatus ) &&
                        <>
                            <div className="col-12 d-flex flex-wrap gap-2 mt-4">
                                
                                {
                                    hours &&
                                    hours.map((item, index) => {
                                        return (
                                            <button 
                                                className="schedule-day" 
                                                onClick={ () => setChoosedHour(item) }
                                                key={ index }
                                            >
                                                <p>{ choosedDay }</p>{item}h
                                            </button>
                                        );
                                    })
                                }
                                
                            </div>
                        </>
                    }
                    {
                        (choosedHour && choosedDay && formatedDay  && !scheduleStatus) &&
                        <ScheduleForm 
                            choosedDay={ choosedDay } 
                            choosedHour={ choosedHour }
                            formatedDay={ formatedDay }
                            setScheduleStatus={ () => setScheduleStatus(true) }
                        />
                    }
                    {
                        scheduleStatus &&
                        <>
                            <div className="text-center mt-5">
                                <h1 className="fw-bolder mb-4">Horário agendado com sucesso!</h1>
                                <h2 className="mb-5">Compareça no local com um documento com foto para ser atendido.</h2>
                                <h3 className="fw-bolder">Nosso Endereço</h3>
                                <h5 className="fs-3">R. José de Santana, 345, sala 203, 2º andar</h5>
                                <h5 className="fs-3">Patos de Minas - MG, 38700-052</h5>
                            </div>
                        </>
                    }
                </div>
            </div>
        </section>
    );
}