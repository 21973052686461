import DOMPurify from 'dompurify';

export default function Lgpd({ content }) {

    const sanitizeContent = DOMPurify.sanitize(content.text, { USE_PROFILES: { html: true } } );

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.description }</p>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 mt-5 px-5 lgpd">
                        <div className="row">
                            <div 
                                className="col-12 about-us-text"
                                dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}