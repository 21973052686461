import UsefulItem from "./UsefulItems/UsefulItem";

export default function UsefulLinks({ content }) {

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.description }</p>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 mt-4 mt-md-5">
                        <div className="row px-5 px-lg-0">
                            <UsefulItem />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}