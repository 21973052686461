import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import logo from './../../assets/images/logo.jpg'
import logoMobile from './../../assets/images/logo-mobile.jpg'
import menuIcon from './../../assets/images/menu.png'

export default function Menu({ menuBind, changeHash }) {

    const [stickyMenu, setStickyMenu] = useState(false);
    const [showLogo, setShowLogo] = useState(true);

    function handleScroll () {
        if (window.innerWidth >= 992 && window.scrollY > 160) {
            setStickyMenu(true);
            setShowLogo(false)
        } else {
            setStickyMenu(false);
            setTimeout(() => {
                setShowLogo(true)
            }, 1800);
        }
    }

    function scrollToTop() { window.scrollTo(0, 0) };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.addEventListener('scroll', handleScroll);
        }
    });

    return (
        <header>
            <div className="container">
                <div className="row py-4 py-sm-5 align-items-center justify-content-between justify-content-lg-around hold-menu">
                    <div className="col-12 col-lg-2 px-5 px-md-0 d-flex justify-content-between">
                        <img src={ logoMobile } alt="" className="logo d-lg-none"/>
                        <img src={ logo } alt="" className={`logo d-none ${showLogo ? 'd-lg-block' : ''}`}/>

                        <button className="menu-button d-block d-lg-none" onClick={() => { menuBind(true); changeHash(); }}>
                            <img src={ menuIcon } alt="" />
                        </button>
                    </div>

                    <div className={`col-lg-10 col-xl-9 d-none d-lg-flex py-4 px-5 wide-menu ${stickyMenu ? "sticky-menu" : ""}`}>
                        <ul className="d-flex m-0 p-0 w-100 justify-content-between">
                            <li><Link to="/" onClick={ () => scrollToTop() }>Início</Link></li>
                            <li><Link to="/sobre-nos" onClick={ () => scrollToTop() }>Sobre Nós</Link></li>
                            <li><Link to="/servicos" onClick={ () => scrollToTop() }>Serviços</Link></li>
                            <li><Link to="/perguntas-frequentes" onClick={ () => scrollToTop() }>Perguntas Frequentes</Link></li>
                            <li><Link to="/galeria" onClick={ () => scrollToTop() }>Galeria</Link></li>
                            <li><Link to="/transparencia" onClick={ () => scrollToTop() }>Transparência</Link></li>
                            <li><Link to="/contato" onClick={ () => scrollToTop() }>Contato</Link></li>
                            <li><Link to="/lgpd" onClick={ () => scrollToTop() }>LGPD</Link></li>

                        </ul>
                    </div>


                </div>
               
            </div>
        </header>
    );
}