import {Outlet, Link} from 'react-router-dom';
import blueMap from './../../assets/images/map-blue.svg'
import mapIcon from './../../assets/images/icon-map.png';
import mailIcon from './../../assets/images/icon-mail.png';
import phoneIcon from './../../assets/images/icon-phone.png';
import whatsappIcon from './../../assets/images/icon-whatsapp.png';
import watchIcon from './../../assets/images/icon-watch.png';

export default function MobileMenu({ menuBind, content }) {

    function removeHash() {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(null, null, ' ');
    }

    function scrollToTop() { window.scrollTo(0, 0) };

    return (
        <>
        <nav className="col menu">
            <div className="mobile-menu w-75 d-flex d-lg-none  flex-column justify-content-start py-5">
                <button className="btn w-100 close-menu-button py-3" onClick={() => { menuBind(false); removeHash(); } }>
                    Fechar Menu
                </button>
                <div className="row py-3">
                    <ul className="d-flex flex-column">
                        <Link to="/" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Início</li>
                        </Link>
                        
                        <Link to="/sobre-nos" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Sobre Nós</li>
                        </Link>

                        <Link to="/servicos" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Serviços</li>
                        </Link>

                        <Link to="/perguntas-frequentes" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Perguntas Frequentes</li>
                        </Link>

                        <Link to="/galeria" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Galeria</li>
                        </Link>

                        <Link to="/transparencia" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Transparência</li>
                        </Link>

                        <Link to="/contato" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">Contato</li>
                        </Link>
                        
                        <Link to="/lgpd" onClick={() => { menuBind(false); scrollToTop(); }}>
                            <li className="py-3 ps-3">LGPD</li>
                        </Link>
                        
                    </ul>
                </div>

                <div className="row d-flex flex-wrap justify-content-center info gap-3">
                    {
                        content.map((item, index) => {
                            return (
                                <div 
                                    className="col-12 py-3 d-flex justify-content-start justify-content-md-center align-items-center"
                                    key={ index }
                                >
                                    <img 
                                        src={ 
                                            item.icon === 'mail' ? mailIcon :
                                            item.icon === 'phone' ? phoneIcon :
                                            item.icon === 'address' ? mapIcon :
                                            item.icon === 'whatsapp' ? whatsappIcon :
                                            watchIcon
                                        } 
                                        alt="" 
                                        className="icon me-3"
                                    />
                                    { item.content }
                                </div>
                            );
                        })   
                    }
                </div>

                <object data={ blueMap } className="background-map">BackgroundMap</object>

            </div>
        </nav>
        <Outlet></Outlet>
        </>
    );
}