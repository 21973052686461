import DOMPurify from 'dompurify';

export default function ServiceItem( {content} ) {

    const sanitizeContent = DOMPurify.sanitize(content.service_description, { USE_PROFILES: { html: true } } );

    return (
        <div className="col-12 col-md-10 offset-md-1 d-flex mb-5">
            <div className='row w-100 service-detail'>
                <div 
                    className="hold-image d-flex justify-content-center align-items-center col-12 col-md-4"
                >
                    <img src={ content.service_image } alt="" />
                </div>
                <div className='col border-start border-2'>
                    <h5 className="service-category">{ content.service_category }</h5>
                    <h4 className="service-title">{ content.service_title }</h4>
                    <div 
                        className="service-description service-description-page"
                        dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                    ></div>
                    <div className="d-flex justify-content-center">
                        <a 
                            href={ content.service_link } 
                            className="service-button service-button-page"
                            target="__blank"
                            rel="noreferrer"
                        >Acessar Serviço</a>
                    </div>
                </div>
            </div>
        </div>
    );
}