export default function LgpdAgreement({ setLgpdAgreement }) {

    function storageLgpdAgreement() {
        if (!localStorage.getItem('rtdpj-lgpd-agreement')) {
            localStorage.setItem('rtdpj-lgpd-agreement', 'true');
        }
    }

    return (
        <div className="lgpd-agreements d-flex flex-column flex-md-row justify-content-center align-items-center w-100">
            <h4 className="pe-5">Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <a href="/lgpd">Política de Privacidade</a> e, ao continuar navegando, você concorda com estas condições</h4>
            <button className="btn text-white my-3 col-12 col-md-3" onClick={ () => { setLgpdAgreement(false); storageLgpdAgreement(); } }>
                Entendido
            </button>
        </div>
    );
}