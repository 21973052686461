import Form from './Contact/Form.js';
import ContactRow from './Contact/ContactRow.js';


export default function Contact({ content }) {

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.description }</p>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 mt-4 mt-md-5">

                        <div className="row px-5 px-lg-0">

                            <div className="col-12 col-lg-5 pb-5 contact-border">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className='fw-bolder mb-3 mb-lg-4'>E-mail e Telefones</h2>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            {
                                                content.contact_items.map((item, index) => {
                                                    return <ContactRow content={ item } key={ index } />;
                                                })
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="col-12 col-lg pb-5 ps-lg-5">
                                <div className="row">
                                    <div className="col-12 mt-5 mt-lg-0">
                                        <h2 className='fw-bolder mb-3 mb-lg-4'>Envie sua Mensagem</h2>
                                    </div>
                                    <Form />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}