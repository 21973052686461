export default function Location({ content }) {

    return (
        <section className="location py-5">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">
                            { content.introduction }
                        </h5>
                        <h2 className="section-title">
                            { content.title }
                        </h2>
                        <p className="section-description">
                            { content.description }
                        </p>
                    </div>
                    <div className="col-12 mt-4 mt-md-5">
                        <div className="w-100">
                            <iframe 
                                title="mapa"
                                width="100%" 
                                height="600" 
                                scrolling="no" 
                                src={ content.link_map }
                            >
                            </iframe>
                            </div>
                        </div>
                </div>
            </div>
        </section>
    );
}