
import React from 'react';
import DOMPurify from 'dompurify';

export default function AboutUs({ content }) {
        
    const sanitizeContent = DOMPurify.sanitize(content.text, { USE_PROFILES: { html: true } } );

    return (
        <section className="py-5 services">
            <div className="container-fluid container-lg">
                <div className="row my-xl-5">
                    <div className="col-12">
                        <h5 className="section-introduction">{ content.introduction }</h5>
                        <h2 className="section-title">{ content.title }</h2>
                        <p className="section-description">{ content.description }</p>
                    </div>
                    <div className="col-12 mt-5 px-5 lgpd">
                        <div className="row">
                            <div className="col-12 col-lg-4 mb-4">
                                <img src={ content.image } alt="" className="w-100"/>
                            </div>
                            <div className="col-12 col-lg-8 ps-lg-4">
                                <div className="row">
                                    <div 
                                        className="col-12 mb-4 about-us-text" 
                                        dangerouslySetInnerHTML={{ __html: sanitizeContent }}
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}