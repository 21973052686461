import {React, useState} from 'react';
import Topbar from './Menu/Topbar';
import Menu from './Menu/Menu';
import MobileMenu from './Menu/MobileMenu';

export default function Fixed({ content }) {

  const [mobileMenu, setMobileMenu] = useState(false);

  window.addEventListener('hashchange', () => {
    const locationHash = window.location.hash;
    if (locationHash !== '#menu') {
      setMobileMenu(false);
    }
  });

  function changeHash() {
    window.location.hash = '#menu';
  }

  return (    
    <>
      <Topbar content={ content }></Topbar>
      <Menu 
        menuBind={() => setMobileMenu(!mobileMenu)} 
        changeHash={() => changeHash()}
      />
      {
        mobileMenu === true && 
        <MobileMenu 
          menuBind={ () => setMobileMenu(!mobileMenu) }
          content={ content }
          changeHash={() => changeHash()}
        />
      }
    </>
  );
}
