export default function UsefulItem({ content }) {
    return (
        <div className="col-12 p-3">
            <div className="useful-item">
                <a href="https://www.cnj.jus.br/corregedoria/justica_aberta/?" target="_blank" rel="noreferrer">
                    <h2 className="fw-bolder">Clique aqui para acessar o Justiça Aberta</h2>
                    <p className="mt-4 fs-3">O Justiça Aberta foi criado em 2007 pela Corregedoria Nacional de Justiça como medida de prevenção à corrupção, controle jurisdicional e transparência. Embora, ao longo dos anos, o sistema tenha se constituído em valiosa fonte de informação para diversas pesquisas e levantamento de dados, ele não foi delineado com essa finalidade. Desde a sua criação, o sistema Justiça Aberta têm sido utilizado pela Corregedoria Nacional de Justiça como instrumento essencial aos procedimentos correicionais. Isso engloba o controle de produtividade dos magistrados de 1º e 2º graus e das serventias judiciais e extrajudiciais.  Diferentemente das estatísticas produzidas a partir do sistema Justiça em Números, que foi formulado para propiciar uma radiografia do judiciário brasileiro, o Justiça Aberta foi delineado para o controle. Por essa razão, o sistema é gerido pela Corregedoria Nacional de Justiça e não pelo Departamento de Pesquisas Judiciárias do CNJ.</p>
                </a>
            </div>
        </div>
    );
}