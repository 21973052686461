import closeIcon from "./../../assets/images/icon-plus.png";

export default function GalleryLightbox({ setLightbox, choosedImage }) {
    return (
        <div className="gallery-lightbox">
            <div className="hold-lightbox-image position-relative">
                <img src={ choosedImage }  alt="" />
                <button className="close-button" onClick={() => setLightbox(false)}>
                    <img src={closeIcon} alt="" />
                </button>
            </div>
        </div>
    );
}